<template>
  <v-alert
    v-if="user && hasTimingPointsAccount && usesOAuth"
    dense
    text
    type="info"
    class="mx-4"
  >
    {{ $t("dashboard.hints.password") }}

    <template #append>
      <router-link :to="{ name: 'Security' }" custom v-slot="{ navigate }">
        <v-btn color="info" icon small @click="navigate">
          <v-icon>mdi-arrow-right-circle</v-icon>
        </v-btn>
      </router-link>
    </template>
  </v-alert>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "PasswordHintBox",
  computed: {
    ...mapState({
      user: state => state.user.user
    }),
    ...mapGetters({
      hasTimingPointsAccount: "user/hasTimingPointsAccount",
      usesOAuth: "user/usesOAuth"
    })
  }
};
</script>
