<template>
  <v-card>
    <br />
    <PasswordHintBox />

    <v-card-title>
      {{ $t("forms.account.title") }}
    </v-card-title>

    <FormUser
      :user="user"
      v-on:formSubmitted="saveUser"
      :error="errorMessage"
      :success="successMessage"
      :isLoading="isLoading"
      submitText="forms.account.submit"
    />
  </v-card>
</template>

<script>
/* eslint-disable */

import { mapState, mapGetters, mapActions } from "vuex";
import FormUser from "@/components/FormUser";
import PasswordHintBox from "@/components/PasswordHintBox";

export default {
  name: "PanelAccountTabAccount",
  components: {
    FormUser,
    PasswordHintBox
  },
  data() {
    return {
      error: null,
      success: null,
      isLoading: false
    };
  },
  computed: {
    ...mapState({
      user: state => state.user.user
    }),
    ...mapGetters({
      hasTimingPointsAccount: "user/hasTimingPointsAccount"
    }),
    errorMessage() {
      return this.$i18n.t(this.error);
    },
    successMessage() {
      return this.$i18n.t(this.success);
    }
  },
  methods: {
    ...mapActions({
      updateUser: "user/updateUser",
      reauthenticateUser: "user/reauthenticateUser"
    }),
    shouldAppendValidClass(field) {
      return !field.$invalid && field.$model && field.$dirty;
    },
    saveUser(form) {
      this.error = null;
      this.success = null;
      this.isLoading = true;

      const oldEmailAddress = this.user.email;

      form.createTimingPointAccount =
        form.createTimingPointAccount && !this.hasTimingPointsAccount;

      this.updateUser(form)
        .then(response => {
          this.isLoading = false;
          if (response.data.status !== 0) {
            return Promise.reject(response);
          }

          const emailChanged = oldEmailAddress !== form.email;
          if (emailChanged) {
            this.success = "forms.account.forcedLogout";
            setTimeout(() => {
              this.reauthenticateUser();
            }, 5000);
          } else {
            this.success = "forms.account.success";
          }
        })
        .catch(response => {
          this.isLoading = false;
          switch (response.data.status) {
            case -1001:
              this.error = "forms.signUp.errors.requiredFields";
              break;
            case -1002:
              this.error = "forms.signUp.errors.existingEmail";
              break;
            case -1005:
              this.error = "forms.account.errors.accountUpdateFailed";
              break;
            default:
              this.error = "errors.unexpectedError";
          }
        });
    }
  }
};
</script>
